import axios from 'axios'
import { router } from '../route'
import { notification } from 'ant-design-vue'

// 默认值
axios.defaults.baseURL = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development' ? '/api' : 'http://localhost:19908'
axios.defaults.headers.post['Content-Type'] = 'application/json'

// 全局请求拦截器
axios.interceptors.request.use((config) => {
    config.headers['Path'] = router.currentRoute.value.path
    return config
}, (err) => {
    console.error(err)
    return Promise.reject(err)
})

// 全局响应拦截器
axios.interceptors.response.use((res) => {
    if (res.data.code !== '200') {
        notification.open({
            message: '结果反馈',
            description: '访问失败: ' + res.data.message,
            onClick: () => {
                // do nothing
            },
        })
        return Promise.reject(res.data)
    }
    return Promise.resolve(res.data)
}, err => {
    notification.open({
        message: '结果反馈',
        description: '访问失败: ' + err.message,
        onClick: () => {
            // do nothing
        },
    })
    return Promise.reject({ data: { message: err.message } })
})

export default axios
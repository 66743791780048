<template>
    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
        <div style="width: 100%;">
            <a-carousel arrows autoplay>
                <template #prevArrow>
                    <img src="@/assets/slices/common_icon_arrow1_left_n@2x.png" class="custom-slick-arrow" style="left: 60px; z-index: 1" />
                </template>
                <template #nextArrow>
                    <img src="@/assets/slices/common_icon_arrow1_right_n@2x.png" class="custom-slick-arrow" style="right: 60px; z-index: 1" />
                </template>
                <div v-for="(item, index) in banners.records" :key="index">
                    <img :src="item.coverUrl"/>
                </div>
            </a-carousel>
        </div>
    </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref } from 'vue'
import { Carousel as ACarousel } from 'ant-design-vue'

const { proxy } = getCurrentInstance()
const banners = ref({
    current: 1,
    size: 10,
    published: true,
    records: [],
    serverType: 'BANNER/PC端',
})
const initData = () => {
    proxy.$axios.get('/common-system-article/page/pub', {
        params: banners.value,
    }).then(res => {
        banners.value = res.data
    })
}
onMounted(() => {
    initData()
})
</script>

<style scoped>
.ant-carousel :deep(.slick-slide img) {
    display: block;
    margin: auto;
    height: 660px;
    width: 100%;
    object-fit: cover;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
    width: 56px;
    height: 56px;
    opacity: 0.3;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
    display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
    opacity: 0.5;
}
</style>
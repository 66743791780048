<template>
    <div
        style="width: 100%; display: flex; justify-content: center; align-items: center; background-color: #FFF; position: sticky; top: 0; z-index: 100; box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.16);">
        <div
            style="max-width: 1440px; width: 100%; padding: 20px 100px 0 100px; display: flex; justify-content: space-between;">
            <!-- logo -->
            <img @click="gotoHome" src="@/assets/slices/navigation_logo@2x.png" alt=""
                style="width: 247px; height: 56px; cursor: pointer; flex-shrink: 0;">
            <div style="width: 185%;"></div>
            <div style="text-align: end; width: 1200%;">
                <div
                    style="display: flex; flex-direction: row-reverse; font-size: 14px; font-weight: 400; line-height: 18px;">
                    <p @click="gotoPath('/communication')" style="margin-left: 28px; cursor: pointer; white-space: nowrap;">对外合作</p>
                    <p style="margin-left: 28px; color: #E2E4E3;">|</p>
                    <p @click="gotoPath('/info')" style="margin-left: 28px; cursor: pointer; white-space: nowrap;">信息公开</p>
                    <p style="margin-left: 28px; color: #E2E4E3;">|</p>
                    <p @click="gotoPath('/build')" style="margin-left: 28px; cursor: pointer; white-space: nowrap;">党建专栏</p>
                </div>
                <div style="margin-top: 18px; display: flex; justify-content: space-between; width: 100%;">
                    <div class="menu" v-for="(menu, index) in menus" :key="index"
                        style="display: flex; flex-direction: column; align-items: center;">
                        <div @click="gotoMenu(menu)" :style="{ cursor: menu.uri ? 'pointer' : 'default' }"
                            style="display: flex; width: 100%;">
                            <div style="width: 30%;"></div>
                            <div :style="{ color: index === actived ? '#028848' : '' }" style="font-size: 16px; font-weight: 600; white-space: nowrap;">{{ menu.name }}</div>
                            <div v-if="index !== menus.length - 1" style="width: 30%;"></div>
                        </div>
                        <div style="position: relative; margin-top: 20px;">
                            <div class="position-menu"
                                style="position: absolute; top: -20px; left: -74px; z-index: 100; padding-top: 7px;">
                                <img src="@/assets/slices/navigation_p@2x.png" alt="" style="width: 148px; height: 8px;">
                                <div v-if="menu.children" style="
                                        display: flex; 
                                        flex-direction: column;
                                        justify-content: center;
                                        padding-top: 9px;
                                        margin-top: -7px;
                                        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.16); 
                                        padding-bottom: 12px; background: #FFF;">
                                    <p v-for="(sub, index) in menu.children" :key="index" 
                                        @click="gotoMenu(sub)"
                                        class="sub-menu-word"
                                        style="width: 148px; line-height: 34px; text-align: center; font-size: 14px; font-weight: 600; cursor: pointer;">
                                        {{ sub.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getCurrentInstance, watch, ref } from 'vue'
import menus from '@/assets/js/menu.js'
import { newWindow } from '@/assets/js/util.js'
const { proxy } = getCurrentInstance()
const actived = ref(-1)
const gotoHome = () => {
    proxy.$router.push('/')
}
const gotoMenu = (menu) => {
    if (menu.uri) {
        if (menu.uri.startsWith('http')) {
            newWindow(menu.uri)
        } else {
            proxy.$router.push({ path: menu.uri, query: menu.query })
        }
    }
}
const gotoPath = (path) => {
    if (path) {
        proxy.$router.push({ path })
    }
}
// eslint-disable-next-line
watch(() => proxy.$route.path, (newPath, oldPath) => {
    let match = -1
    menus.forEach((menu, index) => {
        if (newPath.startsWith(menu.path)) {
            match = index
            return
        }
    })
    actived.value = match
}, { immediate: true })
</script>

<style scoped>
.menu:hover>div>.position-menu {
    opacity: 1;
    display: block;
    animation-duration: 1s;
    animation-name: open;
}

@keyframes open {
    from {
        opacity: 0;
        display: none;
    }

    to {
        opacity: 1;
        display: block;
    }
}

.position-menu:hover {
    opacity: 1;
    display: block;
}

.position-menu {
    opacity: 0;
    display: none;
}


.sub-menu-word:hover {
    background: #E4F4EC;
    color: #028848;
}</style>
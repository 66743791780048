export default [
    {
        name: '学校概况',
        path: '/override',
        uri: '/override',
        children: [
            {
                name: '学校简介',
                uri: '/article/prehandler',
                query: { type: '学校概况/学校简介' },
            },
            {
                name: '学校领导',
                uri: '/override/leader',
            },
            {
                name: '校园风光',
                uri: '/override/environment',
            },
            {
                name: '领导关怀',
                uri: '/override/solicitude',
            },
        ],
    },
    {
        name: '组织机构',
        path: '/dept',
        children: [
            {
                name: '职能部门',
                uri: '/dept/functional',
            },
            {
                name: '教学单位',
                uri: '/dept/college/list',
            },
            {
                name: '教辅单位',
                uri: '/dept/aided',
            },
        ],
    },
    {
        name: '新闻资讯',
        path: '/news',
        uri: '/news',
        children: [
            {
                name: '学校影像',
                uri: '/news',
                query: { tab: '学校影像' },
            },
            {
                name: '学校新闻',
                uri: '/news',
                query: { tab: '学校新闻' },
            },
            {
                name: '通知公告',
                uri: '/news',
                query: { tab: '通知公告' },
            },
            {
                name: '媒体聚焦',
                uri: '/news',
                query: { tab: '媒体聚焦' },
            },
            {
                name: '招标采购',
                uri: '/news',
                query: { tab: '招标采购' },
            },
        ],
    },
    {
        name: '教育教学',
        path: '/teaching',
        uri: '/teaching',
        children: [
            {
                name: '教师风采',
                uri: '/teaching',
                query: { tab: '教师风采' },
            },
            {
                name: '继续教育',
                uri: '/teaching',
                query: { tab: '继续教育' },
            },
            {
                name: '职教活动周专栏',
                uri: '/teaching',
                query: { tab: '职教活动周专栏' },
            },
        ],
    },
    {
        name: '招生就业',
        path: '/enrollment',
        uri: '/enrollment',
        children: [
            {
                name: '招生信息',
                uri: '/enrollment',
                query: { tab: '招生信息' },
            },
            {
                name: '就业信息',
                uri: '/enrollment',
                query: { tab: '就业信息' },
            },
            {
                name: '国际学院',
                uri: '/dept/international',
            },
            // {
            //     name: '成人教育',
            //     uri: '/enrollment',
            //     query: { tab: '成人教育' },
            // },
            {
                name: '创新创业',
                uri: '/enrollment',
                query: { tab: '创新创业' },
            },
            // {
            //     name: '校企合作',
            //     uri: '/enrollment',
            //     query: { tab: '校企合作' },
            // },
            {
                name: '学子故事',
                uri: '/enrollment',
                query: { tab: '学子故事' },
            },
        ],
    },
    {
        name: '科学研究',
        path: '/discovery',
        uri: '/discovery',
        children: [
            {
                name: '实践教学',
                uri: '/discovery',
                query: { tab: '实践教学' },
            },
            {
                name: '教学设备',
                uri: '/discovery',
                query: { tab: '教学设备' },
            },
            {
                name: '科研成果',
                uri: '/discovery',
                query: { tab: '科研成果' },
            },
        ],
    },
    {
        name: '校园文化',
        path: '/school',
        uri: '/school',
        children: [
            {
                name: '学校精神',
                uri: '/school',
                query: { tab: '学校精神' },
            },
            {
                name: '网上团校',
                uri: '/school',
                query: { tab: '网上团校' },
            },
            {
                name: '学生社团',
                uri: '/school',
                query: { tab: '学生社团' },
            },
            {
                name: '农科榜样',
                uri: '/school',
                query: { tab: '农科榜样' },
            },
            {
                name: '微信微博',
                uri: '/school',
                query: { tab: '微信微博' },
            },
        ],
    },
    {
        name: '公共服务',
        path: '/public',
        uri: '/public',
        children: [
            {
                name: 'VR地图',
                uri: 'http://map.edu.bdjwjy.com',
            },
            {
                name: '教学服务',
                uri: '/public',
                query: { tab: '教学服务' },
            },
            {
                name: '生活服务',
                uri: '/public',
                query: { tab: '生活服务' },
            },
            {
                name: '网络服务',
                uri: '/public',
                query: { tab: '网络服务' },
            },
            {
                name: '医疗保障',
                uri: '/public',
                query: { tab: '医疗保障' },
            },
            {
                name: '心理咨询',
                uri: '/public',
                query: { tab: '心理咨询' },
            },
            {
                name: '人才引进',
                uri: '/public',
                query: { tab: '人才引进' },
            },
            {
                name: '安全教育',
                uri: '/public',
                query: { tab: '安全教育' },
            },{
                name: '规章制度',
                uri: '/public',
                query: { tab: '规章制度' },
            },
        ],
    },
]
<template>
    <div>
        <a-alert v-if="typeof detail.published !== 'undefined' && !detail.published" message="此链接为临时链接，仅作预览使用。" type="info" show-icon />
        <div style="height: 160px;">
            <img src="@/assets/slices/common_img_1@2x.png" alt="" style="object-fit: cover; height: 100%; width: 100%;">
            <div style="display: flex; flex-direction: column; align-items: center; height: 100%; margin-top: -160px;">
                <div style="display: inline-block;">
                    <div style="display: inline-block; width: 100%; height: 24px; background-color: #FFF; margin-top: 76px;"></div>
                    <p style="
                        margin-top: -68px;
                        font-size: 44px;
                        font-weight: 400;
                        color: #282A29;
                        line-height: 56px;
                    ">{{ detail?.serverType?.replaceAll('/文章列表','')?.split('/')[detail?.serverType?.replaceAll('/文章列表','')?.split('/')?.length - 1] }}</p>
                </div>
                <p style="
                    margin-top: 8px;
                    font-size: 24px;
                    font-weight: 100;
                    color: #989898;
                    line-height: 32px;
                ">HEADLINES</p>
            </div>
        </div>
        <div style="display: flex; justify-content: center; align-items: center; background: #FFF;">
            <div style="width: 100%; max-width: 1440px; padding: 0 100px;">
                <!-- nav -->
                <div v-if="config && config.nav" style="margin-top: 20px;">
                    <a-space>
                        <a-space v-for="(item, index) in config.nav" :key="index">
                            <p @click="gotoPath(item.uri, item.query)" :style="{ cursor: item.uri ? 'pointer' : 'default' }" style="
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24px;
                                color: #282A29;
                            ">{{ item.name }}</p>
                            <p style="
                                font-size: 16px;
                                font-weight: 400;
                                color: #282A29;
                                line-height: 24px;
                            ">&nbsp;&gt;&nbsp;</p>
                        </a-space>
                        <p style="
                                font-size: 16px;
                                font-weight: 400;
                                color: #282A29;
                                line-height: 24px;
                                color: #028848;
                            ">{{ detail.title }}</p>
                    </a-space>
                </div>
                <!-- title -->
                <div style="display: flex; justify-content: space-between; align-items: flex-end; margin-top: 50px;">
                    <p style="
                        font-size: 26px;
                        font-weight: 600;
                        color: #282A29;
                        line-height: 32px;
                    ">{{ detail.title }}</p>
                    <p style="
                        font-size: 14px;
                        font-weight: 400;
                        color: #989898;
                        line-height: 20px;
                    ">{{ ((detail && detail.watched) ? detail.watched : 0) + ' 浏览' }}</p>
                </div>
                <!-- 分割线 -->
                <div style="
                    width: 100%;
                    border-bottom: 1px solid #E2E4E3;
                    margin-top: 16px;
                "></div>
                <!-- 文章时间创建人 -->
                <div style="display: flex; justify-content: space-between; align-items: flex-end; margin-top: 16px;">
                    <p style="
                        font-size: 14px;
                        font-weight: 400;
                        color: #696969;
                        line-height: 20px;
                    ">{{ '发稿人：' + ((detail && detail.createByName) ? detail.createByName : '') }}</p>
                    <p style="
                        font-size: 14px;
                        font-weight: 400;
                        color: #696969;
                        line-height: 20px;
                    ">{{ (detail && detail.createTime) ? detail.createTime.split(' ')[0] : '' }}</p>
                </div>
                <!-- content -->
                <div id="articleContent" style="
                    margin-top: 28px;
                "></div>
                <div style="display: flex; justify-content: space-between; margin-top: 30px; margin-bottom: 50px;">
                    <!-- left -->
                    <div @mouseenter="mouseIn('up')" @mouseleave="mouseOut('up')" @click="gotoContiguous('up')" style="display: flex; justify-content: center; align-items: center;">
                        <img v-show="!viewCtl.up.hover" src="@/assets/slices/common_icon_pageup_n@2x.png" alt="" style="width: 48px; height: 48px; object-fit: cover;">
                        <img v-show="viewCtl.up.hover" src="@/assets/slices/common_icon_pageup_s@2x.png" alt="" style="width: 48px; height: 48px; object-fit: cover;">
                        <div style="margin-left: 10px; text-align: left;">
                            <p style="
                                font-size: 14px;
                                font-weight: 600;
                                color: #282A29;
                                line-height: 20px;
                            ">上一篇</p>
                            <p style="
                                margin-top: 4px;
                                font-size: 14px;
                                font-weight: 400;
                                color: #696969;
                                line-height: 20px;
                            ">{{ viewCtl.up.detail ? viewCtl.up.detail.title : '没有了！' }}</p>
                        </div>
                    </div>
                    <!-- right -->
                    <div @mouseenter="mouseIn('down')" @mouseleave="mouseOut('down')" @click="gotoContiguous('down')" style="display: flex; justify-content: center; align-items: center;">
                        <div style="margin-right: 10px; text-align: right;">
                            <p style="
                                font-size: 14px;
                                font-weight: 600;
                                color: #282A29;
                                line-height: 20px;
                            ">下一篇</p>
                            <p style="
                                margin-top: 4px;
                                font-size: 14px;
                                font-weight: 400;
                                color: #696969;
                                line-height: 20px;
                            ">{{ viewCtl.down.detail ? viewCtl.down.detail.title : '没有了！' }}</p>
                        </div>
                        <img v-show="!viewCtl.down.hover" src="@/assets/slices/common_icon_pagedown_n@2x.png" alt="" style="width: 48px; height: 48px; object-fit: cover;">
                        <img v-show="viewCtl.down.hover" src="@/assets/slices/common_icon_pagedown_s@2x.png" alt="" style="width: 48px; height: 48px; object-fit: cover;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, onUnmounted } from 'vue'
import { Space as ASpace, Alert as AAlert } from 'ant-design-vue'
import { getConfigByName } from '@/assets/js/articleServerTypes'
const { proxy } = getCurrentInstance()
// Data
const id = ref('')
const detail = ref({})
const viewCtl = ref({
    up: {
        hover: false,
        detail: null,  
    },
    down: {
        hover: false,
        detail: null,  
    }
})
const config = ref({})
//Methods
const watched = () => {
    proxy.$axios.put('/common-system-article/watched?id=' + id.value)
}
const getDetail = () => {
    proxy.$axios.get('/common-system-article/pub', {
        params: {
            id: id.value,
        }
    })
    .then(res => {
        if (!res.data) {
            proxy.$router.push()
        }
        detail.value = res.data
        detail.value.watched = ++detail.value.watched
        let ele = document.getElementById('articleContent')
        if (ele.setHTML) {
            ele.setHTML(detail.value.content)
        } else {
            ele.innerHTML = detail.value.content
        }
        config.value = getConfigByName(detail.value.serverType)
        watched()
        document.title = res.data.title
    })
}
const mouseIn = (side) => {
    if (side === 'up') {
        if (viewCtl.value.up.detail) {
            viewCtl.value.up.hover = true
        }
    } else if (side === 'down') {
        if (viewCtl.value.down.detail) {
            viewCtl.value.down.hover = true
        }
    }
}
const mouseOut = (side) => {
    if (side === 'up') {
        viewCtl.value.up.hover = false
    } else if (side === 'down') {
        viewCtl.value.down.hover = false
    }
}
const resetData = (targetId) => {
    id.value = targetId
    detail.value = {}
    viewCtl.value = {
        up: {
            hover: false,
            detail: null,  
        },
        down: {
            hover: false,
            detail: null,  
        }
    }
    getDetail()
    getContiguous()
}
const gotoContiguous = (side) => {
    if (side === 'up') {
        if (viewCtl.value.up.detail) {
            proxy.$router.push({ path: '/article', query: { id: viewCtl.value.up.detail.id }, replace: true })
            resetData(viewCtl.value.up.detail.id)
        }
    } else if (side === 'down') {
        if (viewCtl.value.down.detail) {
            proxy.$router.push({ path: '/article', query: { id: viewCtl.value.down.detail.id }, replace: true })
            resetData(viewCtl.value.down.detail.id)
        }
    }
}
// 获取相邻文章
const getContiguous = () => {
    proxy.$axios.get('/common-system-article/contiguous', {
        params: {
            id: id.value,
        }
    })
    .then(res => {
        viewCtl.value.up.detail = res.data[0]
        viewCtl.value.down.detail = res.data[1]
    })
}
const gotoPath = (path, query) => {
    if (path) {
        proxy.$router.push({ path, query })
    }
}

onMounted(() => {
    id.value = proxy.$route.query.id
    getDetail()
    getContiguous()
})
onUnmounted(() => {
    document.title = '德阳农业科技职业学院'
})
</script>

<style>
#articleContent img {
    margin: 8px 0;
    max-width: 100% !important;
    object-fit: cover;
}

#articleContent video {
    margin: 8px 0;
    max-width: 100% !important;
}
</style>